import { render, staticRenderFns } from "./ScoreCardMaintenance.vue?vue&type=template&id=422e09fa"
import script from "./ScoreCardMaintenance.vue?vue&type=script&lang=js"
export * from "./ScoreCardMaintenance.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports