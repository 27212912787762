<template>
    <div>
        <v-container fluid>
            <v-row class="ma-3">
                <v-col>
                    <v-btn
                        @click="addMainCriteria()"
                        style="float:right;"
                    >
                        <v-icon>mdi-plus</v-icon><span>Add New Main Criteria</span>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-expansion-panels accordion>
						<v-expansion-panel
							v-for="(superParentItem, superParentIndex) in scoreCard"
							:key="superParentIndex"
						>
							<v-expansion-panel-header>
                                <v-text-field 
                                    v-model="superParentItem.title"
                                    label="Title"
                                    :append-icon="superParentIndex > 0 ? 'mdi-delete' : ''"
                                    @click:append="deleteMainCriteria(superParentItem, superParentIndex)"
                                    >
                                </v-text-field>
                            </v-expansion-panel-header>
							<v-expansion-panel-content>
                                <v-text-field
                                    v-model="superParentItem.description"
                                    label="Description"
                                ></v-text-field>
                                <v-btn
                                    @click="addCriteria(superParentItem)"
                                    >
                                    <v-icon>mdi-plus</v-icon> <span>Add New Criteria</span>
                                </v-btn>
								<v-simple-table>
									<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">
												BUSINESS PARTNER SELECTION CRITERIA 
											</th>
											<th>
												Weight
											</th>
                                            <th class="text-left">
												METRICS
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(parentItem,parentIndex) in superParentItem.criteria" :key="parentIndex">
											<td>	
												<v-text-field
                                                    dense
                                                    v-model="parentItem.name"
                                                    :append-icon="parentIndex > 0 ? 'mdi-delete' : ''"
                                                    @click:append="deleteCriteria(parentItem, parentIndex, superParentIndex)"
                                                ></v-text-field>
											</td>
											<td>	
												<v-text-field
													dense
													v-model="parentItem.weight"
												></v-text-field>
											</td>
                                            <td>
												<v-text-field 
                                                    dense
                                                    v-for="(childItem,childIndex) in parentItem.metrics" :key="childIndex" 
                                                    v-model="childItem.text"
                                                    :append-icon="childIndex > 0 ? 'mdi-delete' : ''"
                                                    @click:append="deleteMetric(childItem,childIndex,parentItem,parentIndex,superParentItem,superParentIndex)"
                                                    >
												</v-text-field>
                                                <v-btn width="100%" class="mb-2" @click="addMetric(parentItem)"><v-icon>mdi-plus</v-icon><span>Add Metric</span></v-btn>
											</td>
										</tr>
									</tbody>
									</template>
								</v-simple-table>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
                </v-col>
                <v-col>
                    <v-btn
                        @click="save"
                        class="primary"
                        style="float:right;"
                        >
                    Save
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Users',
    data () {
        return {
            scoreCard:  []
        }
    },
    async created () {
		const resScoreCard = await this.$store.dispatch('scorecard/doGetScoreCard')
        this.scoreCard = resScoreCard.data.result
	},
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser'
        })
    },
    methods: {
        async save () {
			const res = await this.$store.dispatch('scorecard/doUpdateScoreCard', {data: this.scoreCard})
			if (res.data.code === 200) {
                this.getScoreCard()
			}
        },
        async getScoreCard () {
            const resScoreCard = await this.$store.dispatch('scorecard/doGetScoreCard')
            console.log(resScoreCard)
            this.scoreCard = resScoreCard.data.result
        },
        addMainCriteria () {
            this.scoreCard.push({
				title: '',
				description: '',
				criteria: [
					{
						name: '',
						metrics: [
                            {
                                text: ''
                            },
						],
						weight: 0,
						score: 0
					}
				]
			})
        },
        deleteMainCriteria (superParentItem, superParentIndex) {
            console.log(superParentItem, superParentIndex)
            console.log(this.scoreCard[superParentIndex])
            this.scoreCard.splice(superParentIndex, 1)
        },
        deleteMetric (childItem,childIndex,parentItem,parentIndex,superParentItem,superParentIndex) {
            console.log(this.scoreCard[superParentIndex].criteria[parentIndex].metrics[childIndex])
            this.scoreCard[superParentIndex].criteria[parentIndex].metrics.splice(childIndex, 1)
            console.log(childItem, childIndex, parentItem, parentIndex, superParentItem, superParentIndex)
        },
        deleteCriteria (item, index, superParentIndex) { 
            console.log(item, index, superParentIndex)
            this.scoreCard[superParentIndex].criteria.splice(index, 1)
        },
        addCriteria (item) {
            console.log(item)
            item.criteria.push({		
                name: '',
				metrics: [
                    {
                        text: ''
                    },
                ],
				weight: 0,
                score: 0
			})
        },
        addMetric (item) {
            console.log(item)
            item.metrics.push({text:''})
        }
    }
}
</script>